import { PopoverAnchor } from '@radix-ui/react-popover';
import { CommandInput } from 'cmdk';
import { ComponentPropsWithoutRef, KeyboardEventHandler, useRef } from 'react';
import { IconButton } from '../button/icon-button';
import { SearchIcon } from '../icons/react/search';
import { XmarkCircleIcon } from '../icons/react/xmark-circle';
import { useControllableState } from '../utils/use-controllable-state';
import { useSearchComboboxContext } from './search-combobox-context';
import {
  comboboxInput,
  comboboxInputContainer,
  comboboxReset,
  searchIcon,
} from './search-combobox.css';

export const SearchComboboxInput = ({
  value,
  defaultValue,
  onValueChange,
  onFocus,
  onKeyDown,
  disabled,
  ...props
}: ComponentPropsWithoutRef<typeof CommandInput>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, onQueryChange] = useControllableState({
    value,
    onChange: onValueChange,
    defaultValue: defaultValue as string,
  });

  const { listVisible, setListVisible } = useSearchComboboxContext();

  const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Escape') {
      if (listVisible) {
        setListVisible(false);
      } else {
        onQueryChange('');
      }
    }

    onKeyDown?.(e);
  };

  return (
    <PopoverAnchor
      className={comboboxInputContainer}
      data-disabled={disabled ? '' : undefined}
      data-search-combobox-wrapper
      onClick={() => {
        if (!disabled) inputRef.current?.focus();
      }}
    >
      <SearchIcon className={searchIcon} width={20} height={20} />
      <CommandInput
        ref={inputRef}
        value={query}
        onValueChange={(v) => {
          if (!listVisible) {
            setListVisible(true);
          }
          onQueryChange(v);
        }}
        className={comboboxInput}
        onFocus={(e) => {
          setListVisible(true);
          onFocus?.(e);
        }}
        onKeyDown={onInputKeyDown}
        disabled={disabled}
        {...props}
      />
      <IconButton
        className={comboboxReset}
        data-hidden={Number(query?.length) <= 0 ? '' : undefined}
        onClick={() => {
          onQueryChange('');
        }}
        size="small"
        variant="tertiary"
      >
        <XmarkCircleIcon style={{ height: 20, width: 20 }} />
      </IconButton>
    </PopoverAnchor>
  );
};
